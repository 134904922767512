import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { io } from 'socket.io-client';

const WebSocketComponent = () => {
  const email = localStorage.getItem('email'); // Get the logged-in user's email
  const socketURL  = `wss://alert.arcisai.io:5082`; // WebSocket URL
  const toast = useToast();

  const formatEventType = (eventType) => {
    switch (eventType) {
      case 'HD':
        return 'Human Detection';
      case 'FD':
        return 'Face Detection';
      default:
        return eventType || 'Unknown Event';
    }
  };

  useEffect(() => {
    if (!email) {
      console.error('Email not found in localStorage');
      return;
    }

    const socket = io(socketURL, {
      transports: ['websocket'], // Use WebSocket transport
      reconnection: true, // Enable reconnection
      reconnectionAttempts: 10, // Retry 10 times before giving up
      reconnectionDelay: 3000, // Initial delay between attempts (3 seconds)
      reconnectionDelayMax: 5000, // Maximum delay between attempts (5 seconds)
    });

    socket.on('connect', () => {
      console.log('Socket.IO connection established');
    });
    socket.on('alert', (data) => {
      console.log('Received Socket.IO message:', data);
      // Check if the alert matches the logged-in user's email
      if (data.email === email) {
        // Format the alert data
        const deviceSN = data.deviceSN || 'N/A';
        const timeStamp = data.timeStamp || 'N/A';
        const eventType = formatEventType(data.eventType);

        // Trigger a toast notification
        toast({
          title: 'AI Alert',
          description: (
            <div>
              <p><strong>Device SN:</strong> {deviceSN}</p>
              <p><strong>Event Type:</strong> {eventType}</p>
            </div>
          ),
          status: 'info',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
          containerStyle: {
            borderRadius: '15px',
            backgroundColor: '#4A90E2',
            color: 'white',
          },
        });
      }
    });


    socket.on('disconnect', (reason) => {
      console.warn('Socket.IO connection closed:', reason);
      if (reason === 'io server disconnect') {
        // The server explicitly disconnected the client, reconnect manually
        socket.connect();
      }
    });
    socket.on('connect_error', (error) => {
      console.error('Socket.IO connection error:', error);
    });


    // Cleanup WebSocket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, [email, socketURL, toast]);

  return null; // Component doesn't need to render anything itself, only trigger toasts
};

export default WebSocketComponent;
