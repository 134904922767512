import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  Text,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Grid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Flex,
  Spinner,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CustomCard from "../components/CustomCard";
import { getAllCameras } from "../actions/cameraActions";
import { getReports, getTabluarReport } from "../actions/alertActions";
import { IoDownloadOutline } from "react-icons/io5";
import { jsPDF } from "jspdf"; // Import jsPDF for PDF generation
import "jspdf-autotable"; // Ensure this is imported to extend jsPDF
import MobileHeader from "../components/MobileHeader";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Reports = () => {
  const today = new Date(); // Get today's date
  const [selectedDevice, setSelectedDevice] = useState("");
  const [cameras, setCameras] = useState([]);
  const [reports, setReports] = useState({});
  const [tabluarReport, setTabluarReport] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedEventType, setSelectedEventType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI modal hooks
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [downloadType, setDownloadType] = useState("csv"); // Default to CSV
  const textColor = useColorModeValue("#1A1A1A", "#FFFFFF");
  const bgColor = useColorModeValue("#C8D6E5", "#54637A");

  // Function to fetch all cameras
  const fetchAllCameras = async () => {
    try {
      const response = await getAllCameras();
      setCameras(response.cameras || []);
    } catch (error) {
      console.error("Error fetching cameras:", error);
    }
  };

  // Function to fetch all reports
  const fetchAllReports = async (deviceId, date) => {
    try {
      setLoading(true);
      const response = await getReports(deviceId, date);
      setReports(response || {});
    } catch (error) {
      console.error("Error fetching reports:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch tabular reports
  const fetchTabularReport = async (
    deviceId,
    date,
    page,
    eventType
  ) => {
    try {
      setLoading(true);
      const response = await getTabluarReport(deviceId, date, page, eventType);
      setTabluarReport(response.data || []);
      setTotalPages(response.totalPages || 0);
    } catch (error) {
      console.error("Error fetching tabular reports:", error);
    } finally {
      setLoading(false);
    }
  };

  // function to handle device filter
  const handleDeviceChange = (event) => {
    const newDeviceId = event.target.value;
    setSelectedDevice(newDeviceId);
    fetchAllReports(newDeviceId, selectedDate);
    fetchTabularReport(newDeviceId, selectedDate, currentPage, selectedEventType);
  };

  // Function to format the date as "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Function to handle date change
  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setSelectedDate(formattedDate);
    fetchAllReports(selectedDevice, formattedDate);
    fetchTabularReport(selectedDevice, formattedDate, currentPage, selectedEventType);
  };

  // pagination logic
  const handlePageChange = (direction) => {
    const newPage = direction === "next" ? currentPage + 1 : currentPage - 1;
    setCurrentPage(newPage);
    fetchTabularReport(selectedDevice, selectedDate, newPage, selectedEventType);
  };

  // Function to handle the download button click
  const handleDownloadTypeChange = (event) => {
    setDownloadType(event.target.value);
  };

  // Function to generate and download PDF
  const handleDownload = () => {
    if (downloadType === "csv") {
      // Generate CSV file
      const data = tabluarReport.map(({ deviceSN, timeStamp, eventType }) => ({
        Device: deviceSN,
        Timestamp: timeStamp,
        EventType: eventType,
      }));
      const csvContent =
        "data:text/csv;charset=utf-8," +
        [
          "Device,Timestamp,EventType",
          ...data.map((d) => Object.values(d).join(",")),
        ].join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "tabular_report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (downloadType === "pdf") {
      // Generate PDF file
      const doc = new jsPDF();
      const data = tabluarReport.map((item, index) => ({
        SR_No: index + 1, // SR No is the index + 1 (or whatever logic you need for serial number)
        Device: item.deviceSN,
        Timestamp: item.timeStamp,
        EventType: item.eventType,
      }));

      // Add table to PDF
      const tableData = data.map((d) => [d.Device, d.Timestamp, d.EventType]);
      doc.autoTable({
        head: [["SR No", "Device", "Timestamp", "EventType"]], // Add 'SR No' as a header
        body: data.map((d) => [d.SR_No, d.Device, d.Timestamp, d.EventType]), // Include SR No in body
      });

      // Save PDF
      doc.save("tabular_report.pdf");
    } else if (downloadType === "excel") {
      const data = tabluarReport.map((report, index) => ({
        "SR No": (currentPage - 1) * 10 + index + 1,
        Image: `https://alert.arcisai.io${report.imageUrl}`,
        Device: report.deviceSN,
        "Event Type": report.eventType,
        Timestamp: report.timeStamp,
      }));

      // Create a worksheet from the data
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Report Data");

      // Write the workbook to binary string
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      // Save the file
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      saveAs(blob, "report_data.xlsx");
    }
  };

  // Update handleEventTypeChange function to handle the change
  const handleEventTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedEventType(selectedType);
    setCurrentPage(1);
    if (selectedType === "TOTAL") {
      fetchTabularReport(selectedDevice, selectedDate, currentPage);
    } else {
      fetchTabularReport(
        selectedDevice,
        selectedDate,
        1,
        selectedType
      ); // Include selected eventType in the API call
    }
  };

  // Function to handle the modal open
  const handleOpenModal = (url) => {
    setModalImageUrl(url); // Set the selected URL
    onOpen(); // Open the modal
  };

  // Function to fetch data initially
  useEffect(() => {
    fetchAllCameras();
    fetchTabularReport();
    fetchAllReports();
  }, []);

  return (
    <Box p={3} maxW="1440px" mx="auto" mb={{ base: "20", md: "5" }}>
      {/* Mobile Header */}
      <style>
        {`
          .react-datepicker__day--selected {
            background-color: ${bgColor} !important; /* Green background */
            color: ${textColor} !important; /* White text */
            border-radius: 20%; /* Circular design */
          }

          
        `}
      </style>
      <MobileHeader title="Reports" />

      {/* Common Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
        gap={{ base: 4, md: 6 }}
        mt={{ base: "12", md: "0" }}
      >
        <Text
          display={{ base: "none", md: "block" }}
          fontSize={{ base: "lg", md: "2xl" }}
          fontWeight="bold"
          textAlign={{ base: "center", md: "left" }}
        >
          Reports
        </Text>

        <Flex justifyContent={"space-between"} gap={4}>
          <Select
            value={selectedEventType}
            onChange={handleEventTypeChange}
            placeholder="Select Event Type"
          >
            {reports.eventTypeCounts &&
              Object.keys(reports.eventTypeCounts).map((eventType) => (
                <option key={eventType} value={eventType}>
                  {eventType}
                </option>
              ))}
          </Select>

          <Select
            placeholder="Select Device"
            value={selectedDevice}
            onChange={handleDeviceChange}
            // width={{ base: "full", md: "225px" }}
          >
            {cameras.map((camera) => (
              <option key={camera.deviceId} value={camera.deviceId}>
                {camera.deviceId}
              </option>
            ))}
          </Select>

          <Popover placement="bottom-start">
            <PopoverTrigger>
              <IconButton
                aria-label="Select Date"
                icon={<CalendarIcon />}
                variant="outline"
                // colorScheme="teal"
              />
            </PopoverTrigger>
            <PopoverContent w="fit-content" maxW="320px">
              {" "}
              {/* Adjust width */}
              <PopoverArrow />
              <PopoverBody>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  inline
                  maxDate={today} // Disable future dates
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Box>

      {/* Mobile Date Picker */}
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(5, 1fr)" }}
        gap={4}
        mt={6}
      >
        <CustomCard
          key="totalCount"
          value={reports.totalCount}
          title="Total"
          color={textColor}
          bcolor="white"
        />
        {reports.eventTypeCounts &&
          Object.entries(reports.eventTypeCounts).map(([key, value]) => (
            <CustomCard
              key={key}
              value={value}
              title={key}
              color={textColor}
              bcolor="white"
            />
          ))}
      </Grid>

      {/* tabular report */}
      <Box mt={10}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap="wrap"
          gap={4}
        >
          <Text fontSize="lg" fontWeight="bold">
            Tabular Report
          </Text>

          <Flex gap={4} flexWrap="wrap">
            <Select
              value={downloadType}
              onChange={handleDownloadTypeChange}
              width="auto"
              minWidth="100px"
            >
              <option value="csv">CSV</option>
              <option value="pdf">PDF</option>
              <option value="excel">Excel</option>
            </Select>

            <IconButton
              aria-label="Download Report"
              icon={<IoDownloadOutline size="20px" />}
              onClick={handleDownload}
            />
          </Flex>
        </Flex>

        {loading ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner size="lg" />
          </Flex>
        ) : (
          <>
            {/* For larger screens */}
            <Table
              display={{ base: "none", md: "table" }}
              showColumnBorder={true}
              mt={4}
              variant={"striped"}
            >
              <Thead bg={bgColor} textColor={textColor}>
                <Tr>
                  <Th>SR No</Th>
                  <Th>Image</Th>
                  <Th>Device</Th>
                  <Th>Event Type</Th>
                  <Th>Timestamp</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tabluarReport.length > 0 ? (
                  tabluarReport.map((report, index) => (
                    <Tr key={index}>
                      <Td>{(currentPage - 1) * 10 + index + 1}</Td>
                      <Td>
                        <Image
                          src={`https://alert.arcisai.io${report.imageUrl}`}
                          width={"90px"}
                          cursor="pointer"
                          onClick={() =>
                            handleOpenModal(
                              `https://alert.arcisai.io${report.imageUrl}`
                            )
                          }
                          alt="No Preview Image"
                        />
                      </Td>
                      <Td>{report.deviceSN}</Td>
                      <Td>{report.eventType}</Td>
                      <Td>{report.timeStamp}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="5" textAlign="center">
                      No Data Available
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>

            {/* For smaller screens */}
            <Box display={{ base: "block", md: "none" }} mt={4}>
              {tabluarReport.length > 0 ? (
                tabluarReport.map((report, index) => (
                  <Box
                    key={index}
                    borderWidth="1px"
                    borderRadius="md"
                    overflow="hidden"
                    p={4}
                    mb={4}
                    boxShadow="md"
                  >
                    <Flex justifyContent="space-between" mb={2}>
                      <Text fontWeight="bold">SR No:</Text>
                      <Text>{(currentPage - 1) * 10 + index + 1}</Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb={2}>
                      <Text fontWeight="bold">Image:</Text>
                      <Image
                        src={`https://alert.arcisai.io${report.imageUrl}`}
                        width={"90px"}
                        cursor="pointer"
                        onClick={() =>
                          handleOpenModal(
                            `https://alert.arcisai.io${report.imageUrl}`
                          )
                        }
                        alt="No Preview Image"
                      />
                    </Flex>
                    <Flex justifyContent="space-between" mb={2}>
                      <Text fontWeight="bold">Device:</Text>
                      <Text>{report.deviceSN}</Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb={2}>
                      <Text fontWeight="bold">Event Type:</Text>
                      <Text>{report.eventType}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold">Timestamp:</Text>
                      <Text>{report.timeStamp}</Text>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Text textAlign="center">No Data Available</Text>
              )}
            </Box>
          </>
        )}

        {/* Pagination */}
        <Box display="flex" justifyContent="center" mt={4} alignItems="center">
          <Flex>
            <IconButton
              aria-label="Previous Page"
              icon={<ChevronLeftIcon />}
              onClick={() => handlePageChange("prev")}
              isDisabled={currentPage === 1}
              mr={2}
            />
            <Text alignSelf="center">
              Page {currentPage} of {totalPages}
            </Text>
            <IconButton
              aria-label="Next Page"
              icon={<ChevronRightIcon />}
              onClick={() => handlePageChange("next")}
              isDisabled={currentPage === totalPages}
              ml={2}
            />
          </Flex>
        </Box>
      </Box>

      {/* Modal for Image Preview */}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={modalImageUrl} // Use the image URL from state
              alt="Event Image"
              width="100%" // Full width image inside modal
              objectFit="contain" // Ensures the image scales properly
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Reports;