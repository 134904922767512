import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoMdSend } from "react-icons/io";
// import { MdOutlineAttachFile } from "react-icons/md";
import theme from "../theme";
import { summarizeVideo } from "../actions/aiActions";
import axios from "axios";
import MobileHeader from "../components/MobileHeader";

function ArcisGpt() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [typingText, setTypingText] = useState("");

  const chatBoxRef = useRef(null);

  const userBg = useColorModeValue("custom.primary", "custom.darkModeBg");
  const userColor = useColorModeValue(
    "custom.lightModeText",
    "custom.darkModeText"
  );

  const botBg = useColorModeValue("custom.darkModeBg", "custom.primary");
  const botColor = "black";

  useEffect(() => {
    const fullText = "What can I help you with?";
    let index = 0;

    const typingInterval = setInterval(() => {
      if (index <= fullText.length) {
        setTypingText(fullText.slice(0, index));
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 40);

    return () => clearInterval(typingInterval);
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const downloadFile = async (filePath) => {
    try {
      const fileUrl = `https://media2.arcisai.io:5080/mp4/${filePath}`;
      const response = await axios.get(fileUrl, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Report-${filePath.split("/").pop()}`);

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const formatMessageText = (text) => {
    return text.split("\n").map((line, index) => {
      // Replace **text** with bold Text components
      const parts = line.split(/(\*\*.*?\*\*)/g).map((part, subIndex) => {
        if (part.startsWith("**") && part.endsWith("**")) {
          return (
            <Text as="span" key={subIndex} fontWeight="bold">
              {part.replace(/\*\*/g, "")}
            </Text>
          );
        }
        return (
          <Text as="span" key={subIndex}>
            {part}
          </Text>
        );
      });

      return (
        <Text key={index} fontSize="sm" mb={1}>
          {parts}
        </Text>
      );
    });
  };

  const sendMessage = async () => {
    const trimmedInput = input.trim();
    if (!trimmedInput) return;

    const userMessage = { text: trimmedInput, sender: "user" };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setInput("");

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: "Typing...", sender: "bot" },
    ]);

    try {
      const response = await summarizeVideo(trimmedInput);
      console.log("Response from summarizeVideo:", response);

      // Check for 404 status or specific API error
      if (response.message?.status === 404) {
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          {
            text: "Please Subscribe Your Camera.",
            sender: "bot",
          },
        ]);
        return;
      }

      // Destructure the response data
      const {
        response: text = "",
        file_path: responseFilePath,
        media: responseMedia = null,
      } = response.data || {};

      // Handle multiple media URLs (assuming they are comma-separated or an array)
      const mediaUrls = Array.isArray(responseMedia)
        ? responseMedia
        : responseMedia?.split(",").map((url) => url.trim()) || [];

      // Construct the bot message
      const botMessage = {
        text: text || "No response available.",
        sender: "bot",
        filePath: responseFilePath,
        videoUrls: mediaUrls, // Store multiple video URLs
      };

      // Update messages with the bot's response
      setMessages((prevMessages) => [...prevMessages.slice(0, -1), botMessage]);
    } catch (error) {
      console.error("Error in summarizeVideo:", error);

      // Handle errors
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        "Something went wrong. Please try again.";

      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        {
          text: errorMessage,
          sender: "bot",
        },
      ]);
    }
  };

  return (
    <Box
      height="80vh"
      maxW="1440px"
      mx="auto"
      // pl={6}
      // pr={6}
      p={3}
      mt={{ base: "4", md: "0" }}
      display="flex"
      flexDirection="column"
    >
      <MobileHeader title="ArcisGPT" />

      {/* Header Section */}
      <Box mt={{ base: "10", md: "4" }}>
        <Image src="./images/ChatPlusArcis.png" alt="ArcisGPT Logo" mb={4} />
      </Box>

      {/* Chat Section */}
      <Box
        ref={chatBoxRef}
        flex="1"
        overflowY="auto"
        mt={4}
        position="relative"
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        {messages.length === 0 ? (
          <Flex justify="center" align="center" height="100%">
            <Text
              fontSize="lg"
              fontWeight="bold"
              textAlign="center"
              className="wave-text"
            >
              {typingText || " "}
            </Text>
          </Flex>
        ) : (
          <Flex direction="column" gap={4}>
            {messages.map((msg, index) => (
              <Flex
                key={index}
                justify={msg.sender === "user" ? "flex-end" : "flex-start"}
              >
                {msg.sender === "bot" && <Avatar name="ArcisGPT" mr={3} />}
                <Box
                  p={4}
                  borderRadius="md"
                  boxShadow="base"
                  maxW="60%"
                  bg={msg.sender === "user" ? userBg : botBg}
                  color={msg.sender === "user" ? userColor : botColor}
                >
                  {msg.sender === "bot" ? (
                    formatMessageText(msg.text)
                  ) : (
                    <Text fontSize="sm">{msg.text}</Text>
                  )}

                  {msg.filePath && (
                    <Box mt={2}>
                      <Text
                        fontSize="sm"
                        color="blue.500"
                        fontWeight="bold"
                        cursor="pointer"
                        onClick={() => downloadFile(msg.filePath)}
                      >
                        Download PDF Report
                      </Text>
                    </Box>
                  )}

                  {msg.videoUrls &&
                    msg.videoUrls.length > 0 &&
                    msg.videoUrls.map(
                      (videoUrl, idx) =>
                        videoUrl && ( // Ensure each individual videoUrl is not null or empty
                          <Box mt={4} key={idx}>
                            <video
                              controls
                              width="100%"
                              style={{
                                borderRadius: "8px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <source
                                src={`https://media2.arcisai.io:5080/mp4/${videoUrl}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </Box>
                        )
                    )}
                </Box>
              </Flex>
            ))}
          </Flex>
        )}
      </Box>

      {/* Input Section */}
      <Box mt={4}>
        <InputGroup size="md">
          {/* <InputLeftElement cursor="pointer">
            <Icon as={MdOutlineAttachFile} boxSize={5} />
          </InputLeftElement> */}

          <Input
            placeholder="Ask Me Anything..."
            pl="12"
            pr="12"
            borderRadius="8px"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") sendMessage();
            }}
            _focus={{
              borderColor: theme.colors.custom.primary,
              boxShadow: `0 0 0 1px ${theme.colors.custom.primary}`,
            }}
          />

          <InputRightElement cursor="pointer" onClick={sendMessage}>
            <Icon as={IoMdSend} boxSize={5} />
          </InputRightElement>
        </InputGroup>
      </Box>
    </Box>
  );
}

export default ArcisGpt;
