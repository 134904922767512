import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Button,
  HStack,
  Flex,
  SimpleGrid,
  Input,
  Checkbox,
  Image,
  MenuList,
  MenuItem,
  Menu,
  MenuButton,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getAllCameras, getOnlineCameras } from "../actions/cameraActions";
import { getDataByDate, getModelNames } from "../actions/aiActions";
import { FiMoreVertical } from "react-icons/fi";
import { edgeEvent } from "../actions/settingsActions";
import Player from "../components/Player";
import JessibucaPlayer from "react-jessibuca";
import MobileHeader from "../components/MobileHeader";

const Calendar = ({ onDateChange }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const getWeekDays = (date) => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to Sunday

    return Array.from({ length: 7 }, (_, index) => {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + index);
      return day;
    });
  };

  const weekDays = getWeekDays(currentDate);

  const handlePrevDay = () => {
    const prevDay = new Date(currentDate);
    prevDay.setDate(currentDate.getDate() - 1);
    setCurrentDate(prevDay);
  };

  const handleNextDay = () => {
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remove time component for comparison

    if (nextDay <= today) {
      setCurrentDate(nextDay);
    }
  };

  const handleDateClick = (day) => {
    setCurrentDate(day);
    const formattedDate = day.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    console.log("Selected date:", formattedDate);
    onDateChange(formattedDate); // Call the parent function passed via props to update the date in the Events component
  };

  const handleDateChange = (date) => {
    onDateChange(date); // Call the parent function passed via props
  };

  const PrevIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
    >
      <path
        d="M2.41224 6.49861L7.70777 1.58475C7.80056 1.49842 7.87412 1.39596 7.92426 1.28323C7.9744 1.17051 8.00014 1.04971 8 0.927749C7.99986 0.805786 7.97385 0.685042 7.92345 0.572413C7.87305 0.459783 7.79925 0.357473 7.70627 0.271324C7.61328 0.185174 7.50293 0.116873 7.38152 0.070319C7.2601 0.0237654 7.13 -0.000128814 6.99864 5.22258e-07C6.86727 0.000129858 6.73723 0.0242804 6.61592 0.0710731C6.49461 0.117866 6.38441 0.186384 6.29163 0.272716L0.291025 5.84398C0.10976 6.01319 0.00562136 6.24069 0.000220993 6.47929C-0.00517937 6.71788 0.0885729 6.94925 0.262022 7.12537L6.28662 12.7273C6.47402 12.9016 6.72834 12.9997 6.99363 13C7.25893 13.0003 7.51347 12.9027 7.70127 12.7287C7.88906 12.5547 7.99472 12.3186 7.995 12.0723C7.99528 11.8259 7.89016 11.5896 7.70277 11.4153L2.41224 6.49861Z"
        fill="#141E35"
      />
    </svg>
  );

  const NextIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
    >
      <path
        d="M5.58776 6.50139L0.292233 11.4152C0.199445 11.5016 0.12588 11.604 0.0757385 11.7168C0.0255972 11.8295 -0.000137237 11.9503 1.51162e-06 12.0723C0.000140737 12.1942 0.0261527 12.315 0.076551 12.4276C0.12695 12.5402 0.200749 12.6425 0.293734 12.7287C0.386719 12.8148 0.497069 12.8831 0.618485 12.9297C0.739901 12.9762 0.870003 13.0001 1.00136 13C1.13273 12.9999 1.26277 12.9757 1.38408 12.9289C1.50539 12.8821 1.61559 12.8136 1.70837 12.7273L7.70898 7.15602C7.89024 6.98681 7.99438 6.75931 7.99978 6.52071C8.00518 6.28211 7.91143 6.05075 7.73798 5.87462L1.71338 0.272714C1.52598 0.0983585 1.27166 0.000258811 1.00637 -1.56508e-06C0.74107 -0.000262895 0.486527 0.0973342 0.298735 0.271321C0.110943 0.445307 0.00528539 0.68143 0.00500403 0.927745C0.00472268 1.17406 0.109841 1.41039 0.297236 1.58475L5.58776 6.50139Z"
        fill="#141E35"
      />
    </svg>
  );

  return (
    <Box align="center">
      <Text
        sx={{
          color: "var(--primary-txt, #141E35)",
          fontFamily: "Inter",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "normal",
          textTransform: "capitalize",
          textAlign: "left",
          paddingLeft: "25px",
        }}
      >
        Select Date
      </Text>

      <Flex width="100%" alignItems="center" mt={2}>
        <Button
          onClick={handlePrevDay}
          colorScheme="gray"
          leftIcon={<PrevIcon />}
          sx={{
            border: "none", // Removes the border
            background: "transparent", // Transparent background
            _hover: { background: "transparent" }, // No hover effect
            _focus: { boxShadow: "none" }, // Removes focus outline
          }}
        />

        <SimpleGrid columns={7} spacing={2} flex="1">
          {weekDays.map((day) => (
            <Box
              key={day}
              width="35px"
              height="40px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              borderWidth="1px"
              borderRadius="md"
              bg={
                day.toDateString() === currentDate.toDateString()
                  ? "#C8D6E5"
                  : "white"
              }
              textAlign="center"
              cursor="pointer" // Set cursor to pointer
              onClick={() => handleDateClick(day)}
            >
              <Text
                sx={{
                  color: "var(--secondary-txt, #65758B)",
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {day.toLocaleString("default", { weekday: "long" }).slice(0, 2)}
              </Text>
              <Text
                sx={{
                  color: "var(--primary-txt, #141E35)",
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {day.getDate()}
              </Text>
            </Box>
          ))}
        </SimpleGrid>

        <Button
          onClick={handleNextDay}
          colorScheme="gray"
          rightIcon={<NextIcon />}
          sx={{
            border: "none", // Removes the border
            background: "transparent", // Transparent background
            _hover: { background: "transparent" }, // No hover effect
            _focus: { boxShadow: "none" }, // Removes focus outline
          }}
        />
      </Flex>
    </Box>
  );
};

const Events = () => {
  const bgColor = useColorModeValue("custom.primary", "custom.darkModePrimary");
  const tabBg = useColorModeValue("#F1EFFE", "#5F4BB6");
  const tabActiveColor = useColorModeValue(
    "custom.lightModeText",
    "custom.darkModeText"
  );
  const tabInactiveColor = useColorModeValue("#65758B", "custom.tabDarkMode");
  const textColor = useColorModeValue(
    "custom.lightModeText",
    "custom.darkModeText"
  );

  const selectedTab = useColorModeValue(
    "custom.primary",
    "custom.darkModePrimary"
  );

  const options = [
    { value: "0x2", label: "Motion Detection" },
    { value: "0x10", label: "Human Detection" },
    { value: "0x20", label: "Face Detection" },
    { value: "0x2000", label: "Line Crossing" },
    { value: "0x4000", label: "Region Enter" },
    { value: "0x8000", label: "Region Exit" },
    { value: "0x10000", label: "Intrude" },
    { value: "0x20000", label: "Un-Attended" },
    { value: "0x40000", label: "Object Remove" },
  ];

  const [selectedImage, setSelectedImage] = useState(""); // State for the selected image
  const {
    isOpen: isImageModalOpen,
    onOpen: openImageModal,
    onClose: closeImageModal,
  } = useDisclosure(); // Modal state renamed

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [activeTab, setActiveTab] = useState("");

  const openVideoModal = () => setIsVideoModalOpen(true);
  const closeVideoModal = () => setIsVideoModalOpen(false);

  const todayDate = new Date().toISOString().split("T")[0];

  const [isDeviceIdInitialized, setIsDeviceIdInitialized] = useState(false); // Tracks if the default deviceId has been initialized
  const [cameras, setCameras] = useState([]);
  const [image, setImage] = useState([]);
  const [pageNumber, setPageNumber] = useState(1); // Current page state
  const [onlineCamera, setOnlineCamera] = useState([]); // Total number of pages
  const [edgeEvents, setEdgeEvents] = useState([]); // Total number of pages
  const [deviceId, setDeviceId] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]); // State for storing selected device IDs
  const [selectedEvents, setSelectedEvents] = useState([]); // State for storing selected events
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [TypeFlags2, setTypeFlags2] = useState("0x10"); // Default value
  const [cameraName, setCameraName] = useState(""); // Initial empty state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [appliedDeviceId, setAppliedDeviceId] = useState(null);
  const [appliedEventFlags, setAppliedEventFlags] = useState([]);
  const [modelName, setModelName] = useState([]);
  const [cloudPages, setCloudPages] = useState(1);

  const [allCamera, setAllCamera] = useState([]);

  const [isEdgeModalOpen, setIsEdgeModalOpen] = useState(false); // Edge Event Filter Modal
  const [isCloudModalOpen, setIsCloudModalOpen] = useState(false); // Cloud Filter Modal

  // Close functions for individual modals
  const closeEdgeModal = () => setIsEdgeModalOpen(false);
  const closeCloudModal = () => setIsCloudModalOpen(false);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate); // Update the date when Calendar sends a new one
  };

  // for edge events
  useEffect(() => {
    const fetchData = async () => {
      try {
        const date = selectedDate || new Date().toISOString().split("T")[0]; // Default to today's date if no date is selected
        const limit = 10;
        console.log("Fetching event data...");

        const onlineCameraData = await getOnlineCameras();
        setOnlineCamera(onlineCameraData.data);
        console.log("Online cameras:", onlineCameraData.data);

        // Step 3: Initialize deviceId if not already set
        if (!isDeviceIdInitialized && onlineCameraData.data.length > 0) {
          const defaultDeviceId = onlineCameraData.data[0].deviceId;
          setDeviceId(defaultDeviceId); // Set default deviceId
          setIsDeviceIdInitialized(true); // Mark as initialized to avoid resetting on re-renders
          console.log("Default deviceId initialized:", defaultDeviceId);
        }

        // Step 4: Fetch edge events based on filters
        if (deviceId) {
          const pageSize = 10;
          const p2porigin = "torqueverse.dev";

          if (selectedDeviceIds.length > 0 && selectedEvents.length > 0) {
            console.log("Applying filters:", selectedDeviceIds, selectedEvents);

            const eventPromises = selectedEvents.map((eventFlag) =>
              edgeEvent(
                deviceId,
                pageNumber,
                date,
                p2porigin,
                eventFlag,
                pageSize
              )
                .then((response) => response?.Item ?? [])
                .catch((error) => {
                  console.error("Error fetching edge event:", eventFlag, error);
                  return [];
                })
            );

            const allFilteredData = (await Promise.all(eventPromises)).flat();
            console.log("Filtered edge events:", allFilteredData);

            setEdgeEvents(allFilteredData.length > 0 ? allFilteredData : []);
          } else {
            console.log("No filters applied. Fetching general edge events.");

            const edgeEventsResponse = await edgeEvent(
              deviceId,
              pageNumber,
              date,
              p2porigin,
              TypeFlags2,
              pageSize
            );

            console.log("Edge Events Response:", edgeEventsResponse);

            if (
              Array.isArray(edgeEventsResponse.Item) &&
              edgeEventsResponse.Item.length > 0
            ) {
              setEdgeEvents(edgeEventsResponse.Item); // Update edgeEvents state with fetched data
            } else {
              console.log("No edge events found");
              setEdgeEvents([]); // Set an empty array for empty state
            }
          }
        } else {
          console.warn("No deviceId found for edge events");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData(); // Call fetchData on dependency changes
  }, [
    pageNumber,
    selectedDate,
    TypeFlags2,
    deviceId,
    isDeviceIdInitialized,
    selectedDeviceIds,
    selectedEvents,
  ]);

  //for cloud events
  useEffect(() => {
    const fetchCloudImages = async () => {
      try {
        const limit = 10;
        const cloudImage = await getDataByDate(pageNumber, limit, selectedDate);
        setCloudPages(cloudImage.totalPages);

        // Ensure `allData` is always an array before setting the state
        setImage(cloudImage.allData || []); // Use empty array if no data
      } catch (error) {
        console.error("Error fetching cloud images:", error);
        setImage([]); // Set an empty array if an error occurs
      }
    };

    fetchCloudImages(); // Call the async function
  }, [selectedDate, pageNumber]); // Re-fetch data when `selectedDate` or `pageNumber` changes

  const handleNextPage = () => {
    console.log("pages :: ", pageNumber, cloudPages)
    if(pageNumber < cloudPages){
      const nextPage = pageNumber + 1;
      setPageNumber(nextPage); // Update state
    }
    console.log("page number :: ",pageNumber)
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      const prevPage = pageNumber - 1;
      setPageNumber(prevPage); // Update state
    }
  };

  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedDeviceIds((prevIds) =>
      checked ? [...prevIds, value] : prevIds.filter((id) => id !== value)
    );
  };

  const handleEventCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedEvents((prevEvents) =>
      checked
        ? [...prevEvents, value]
        : prevEvents.filter((event) => event !== value)
    );
  };

  const handleApply = async () => {
    try {
      if (selectedDeviceIds.length === 0 || selectedEvents.length === 0) {
        setError("Please select a device and at least one event.");
        return;
      }

      setLoading(true);
      setError(null);

      const deviceId = selectedDeviceIds[0];
      const formattedDate =
        selectedDate || new Date().toISOString().split("T")[0];
      const p2porigin = "torqueverse.dev";
      const pageSize = 10;

      const eventPromises = selectedEvents.map((eventFlag) =>
        edgeEvent(
          deviceId,
          pageNumber,
          formattedDate,
          p2porigin,
          eventFlag,
          pageSize
        )
          .then((response) => response?.Item ?? [])
          .catch(() => [])
      );

      const allEventsData = (await Promise.all(eventPromises)).flat();

      if (allEventsData.length > 0) {
        setEdgeEvents(allEventsData);
      } else {
        setEdgeEvents([]);
        setError("No events found for the selected filters.");
      }

      // Store applied filters
      setAppliedDeviceId(deviceId);
      setAppliedEventFlags(selectedEvents);

      setIsModalOpen(false);
    } catch (error) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Edge Events state updated:", edgeEvents);
    setEdgeEvents(edgeEvents);
  }, [edgeEvents]);

  return (
    <Box p={3} maxW="1440px" mx="auto">
      <MobileHeader title="Events" />

      <Tabs
        variant="unstyled"
        mt={{ base: "12", md: "0" }}
        borderRadius="10px"
        mx="auto"
      >
        <Flex alignItems="center" justifyContent="center">
          <TabList
            variant="filled"
            bg={useColorModeValue(
              "custom.tabInactiveLightBg",
              "custom.tabInactiveDarkBg"
            )}
            borderRadius="10px"
            boxShadow="1px 1px 10px 0px rgba(0, 0, 0, 0.13) inset"
            width="400px"
            borderColor="gray.200"
          >
            <Tab
              _selected={{
                bg: selectedTab,
                color: tabActiveColor,
                borderRadius: "10px",
                fontWeight: "bold",
              }}
              px={{ base: 0, md: 6 }}
              py={1.5}
              borderRadius="full"
              color={tabInactiveColor}
              h="full" // Ensure full height for consistency
              w="50%"
              onClick={() => setActiveTab("Cloud Events")} // Set activeTab to 'Edge Events'
            >
              Cloud Events
            </Tab>

            <Tab
              _selected={{
                bg: selectedTab,
                color: tabActiveColor,
                borderRadius: "10px",
                fontWeight: "bold",
              }}
              px={{ base: 0, md: 6 }}
              py={1.5}
              borderRadius="full"
              color={tabInactiveColor}
              w="50%" // Full width on mobile
              h="full"
              onClick={() => setActiveTab("Edge Events")} // Set activeTab to 'Edge Events'
            >
              Edge Events
            </Tab>
          </TabList>
        </Flex>

        <TabPanels>
          <TabPanel>
            <Flex alignItems="center" justifyContent="space-between" mt={4}>
              <Box>
                <Text
                  color={textColor}
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="32px"
                >
                  Cloud Events
                </Text>
                <Text
                  color="var(--secondary-txt, #65758B)"
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="24px"
                >
                  List of events triggered by camera
                </Text>
              </Box>

              <Calendar onDateChange={handleDateChange} />
            </Flex>

            <HStack spacing={4} mt={5} justifyContent="space-between">
              <HStack spacing={4}>
                <Button
                  // colorScheme="purple"
                  backgroundColor={bgColor}
                  variant="solid"
                  border="none"
                  onClick={() => setIsCloudModalOpen(true)}
                >
                  Filters
                </Button>
                <Button
                  variant="link"
                  color="var(--secondary-txt, #65758B)"
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    textDecoration: "underline",
                    textDecorationStyle: "solid",
                    textDecorationSkipInk: "none",
                    textDecorationThickness: "auto",
                    textUnderlineOffset: "auto",
                    textUnderlinePosition: "from-font",
                  }}
                >
                  Clear all
                </Button>
              </HStack>

              <HStack spacing={8} alignItems="center">
                <Box width="100%">
                  <Text mb={1}>From</Text>
                  <Input
                    type="time"
                    value={fromTime}
                    onChange={(e) => setFromTime(e.target.value)}
                    size="lg"
                    width="140px"
                    height="30px"
                    fontSize="lg"
                  />
                </Box>
                <Box width="100%">
                  <Text mb={1}>To</Text>
                  <Input
                    type="time"
                    value={toTime}
                    onChange={(e) => setToTime(e.target.value)}
                    size="lg" // Change size to 'lg' for larger input
                    width="140px" // Set the width of the input
                    height="30px" // Set the height of the input
                    fontSize="lg" // Increase font size for better visibility
                  />
                </Box>
              </HStack>
            </HStack>
            <SimpleGrid columns={{ base: 2, md: 3 }} spacing={6} mt={4}>
              {Array.isArray(image) && image.length > 0 ? (
                image.map((img) => (
                  <Box
                    key={img.imgurl}
                    borderRadius="8px"
                    overflow="hidden"
                    w={"auto"}
                    flexShrink={0}
                    onClick={() => {
                      setSelectedImage(`${img.imgurl}`); // Set the selected image URL dynamically
                      openImageModal(); // Open the image modal
                    }}
                    _hover={{ cursor: "pointer" }} // Optionally, change cursor to pointer on hover
                  >
                    <Box position="relative" w="100%">
                      <Image
                        src={`${img.imgurl}`}
                        alt={img.title || "Image"}
                        width="100%"
                        borderRadius="8px 8px 0 0"
                        height={["200px", "242px"]}
                        objectFit="cover"
                      />

                      <Box
                        position="absolute"
                        top="8px"
                        right="8px"
                        display="flex"
                        gap="4px"
                      >
                        {img.eventTypes?.map((type, index) => (
                          <Box
                            key={index}
                            bg={type.color}
                            borderRadius="full"
                            width="10px"
                            height="10px"
                          />
                        ))}
                      </Box>
                    </Box>

                    {/* Camera name and details */}
                    <Box bg={bgColor} p={2}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Box>
                          <Text fontWeight="bold">
                            Device: {img.deviceId || "Unknown"}
                          </Text>
                          <Text>Type: {img.modelname || "N/A"}</Text>
                          <Text color="gray.500" fontSize="sm">
                            Time: {img.sendtime || "Unknown"}
                          </Text>
                        </Box>

                        {/* Dropdown with options */}
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<FiMoreVertical />}
                            variant="ghost"
                            size="sm"
                            aria-label="Options"
                          />
                          <MenuList>
                            <MenuItem
                              onClick={() => console.log("Event Info Clicked")}
                            >
                              Event Info
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                console.log("Generate Report Clicked")
                              }
                            >
                              Generate Report
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="75vw" // Full width of the viewport
                >
                  <Box
                    textAlign="center"
                    color="gray.500"
                    p={4}
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="md"
                    maxW="500px" // Optional: Limit width of the child Box
                    width="100%" // Ensure full width within maxW constraint
                  >
                    <Text fontSize="lg" fontWeight="bold">
                      No events found
                    </Text>
                    <Text>
                      Please check back later or try a different filter.
                    </Text>
                  </Box>
                </Box>
              )}
            </SimpleGrid>
          </TabPanel>

          {/* Edge Events */}
          <TabPanel>
            <Flex alignItems="center" justifyContent="space-between" mt={4}>
              <Box>
                <Text
                  color="var(--primary-txt, #141E35)"
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="32px"
                >
                  Edge Events
                </Text>
                <Text
                  color="var(--secondary-txt, #65758B)"
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="24px"
                >
                  List of events triggered by camera
                </Text>
              </Box>

              <Calendar onDateChange={handleDateChange} />
            </Flex>

            <HStack spacing={4} mt={5} justifyContent="space-between">
              <HStack spacing={4}>
                <Button
                  // colorScheme="purple"
                  backgroundColor={"#C8D6E5"}
                  variant="solid"
                  border="none"
                  onClick={() => setIsEdgeModalOpen(true)}
                >
                  Filters
                </Button>
                <Button
                  variant="link"
                  color="var(--secondary-txt, #65758B)"
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    textDecoration: "underline",
                    textDecorationStyle: "solid",
                    textDecorationSkipInk: "none",
                    textDecorationThickness: "auto",
                    textUnderlineOffset: "auto",
                    textUnderlinePosition: "from-font",
                  }}
                >
                  Clear all
                </Button>
              </HStack>

              <HStack spacing={8} alignItems="center">
                <Box width="100%">
                  <Text mb={1}>From</Text>
                  <Input
                    type="time"
                    value={fromTime}
                    onChange={(e) => setFromTime(e.target.value)}
                    size="lg"
                    width="140px"
                    height="30px"
                    fontSize="lg"
                  />
                </Box>
                <Box width="100%">
                  <Text mb={1}>To</Text>
                  <Input
                    type="time"
                    value={toTime}
                    onChange={(e) => setToTime(e.target.value)}
                    size="lg" // Change size to 'lg' for larger input
                    width="140px" // Set the width of the input
                    height="30px" // Set the height of the input
                    fontSize="lg" // Increase font size for better visibility
                  />
                </Box>
              </HStack>
            </HStack>
            <SimpleGrid columns={{ base: 2, md: 3 }} spacing={6} mt={4}>
              {Array.isArray(edgeEvents) && edgeEvents.length > 0 ? (
                edgeEvents.map((img) => (
                  <Box
                    key={img?.id || Math.random()} // Use a fallback if `img.id` is missing
                    borderRadius="8px"
                    overflow="hidden"
                    w={"auto"}
                    flexShrink={0}
                    onClick={() => {
                      // Generate video URL with default origin
                      const url = `https://${deviceId}.torqueverse.dev/flv/pb?pb_${img?.TimeStart}_${img?.TimeEnd}_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`;
                      setVideoUrl(url);
                      setIsVideoModalOpen(true);
                    }}
                    _hover={{ cursor: "pointer" }} // Optionally, change cursor to pointer on hover
                  >
                    {/* Image section */}
                    <Box position="relative" w="100%">
                      <Image
                        src={img?.imageUrl || "./images/CameraCard.png"} // Fallback if `img.imageUrl` is missing
                        alt={img?.title || "Event Image"} // Fallback if `img.title` is missing
                        width="100%"
                        borderRadius="8px 8px 0 0"
                        height={["200px", "242px"]}
                        objectFit="cover"
                      />

                      {/* Event type icons */}
                      <Box
                        position="absolute"
                        top="8px"
                        right="8px"
                        display="flex"
                        gap="4px"
                      >
                        {Array.isArray(img?.eventTypes) &&
                          img?.eventTypes.map((type, index) => (
                            <Box
                              key={index}
                              bg={type?.color || "gray"} // Fallback color if `type.color` is missing
                              borderRadius="full"
                              width="10px"
                              height="10px"
                            />
                          ))}
                      </Box>
                    </Box>

                    {/* Camera name and details */}
                    <Box bg={bgColor} p={2}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Box>
                          <Text fontWeight="bold">{deviceId}</Text>{" "}
                          {/* Fallback if `img.deviceName` is missing */}
                          <Text>Type: {img?.Type || "N/A"}</Text>{" "}
                          {/* Fallback if `img.Type` is missing */}
                          <Text color="gray.500" fontSize="sm">
                            Duration:{" "}
                            {img?.TimeStart && img?.TimeEnd
                              ? `${Math.floor(
                                  (img?.TimeEnd - img?.TimeStart) / 3600
                                )} hours ${Math.floor(
                                  ((img?.TimeEnd - img?.TimeStart) % 3600) / 60
                                )} minutes ${(
                                  (img?.TimeEnd - img?.TimeStart) %
                                  60
                                ).toFixed(0)} seconds`
                              : "Invalid time"}
                          </Text>
                        </Box>

                        {/* Dropdown with options */}
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<FiMoreVertical />}
                            variant="ghost"
                            size="sm"
                            aria-label="Options"
                          />
                          <MenuList>
                            <MenuItem
                              onClick={() => console.log("Event Info Clicked")}
                            >
                              Event Info
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                console.log("Generate Report Clicked")
                              }
                            >
                              Generate Report
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="70vw" // Full width of the viewport
                >
                  <Box
                    textAlign="center"
                    color="gray.500"
                    p={4}
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="md"
                    maxW="500px" // Optional: Limit width of the child Box
                    width="100%" // Ensure full width within maxW constraint
                  >
                    <Text fontSize="lg" fontWeight="bold">
                      No events found
                    </Text>
                    <Text>
                      Please check back later or try a different filter.
                    </Text>
                  </Box>
                </Box>
              )}
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={isEdgeModalOpen} onClose={closeEdgeModal} isCentered>
        <ModalOverlay />
        <ModalContent maxW="650px" maxH="80vh">
          <ModalHeader>Event Filter</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto">
            <Tabs variant="enclosed">
              <Center width="100%">
                <TabList>
                  <Tab>Camera List</Tab>
                  <Tab>Events</Tab>
                </TabList>
              </Center>
              <TabPanels>
                <TabPanel>
                  <Box width="100%">
                    <Input placeholder="Type to search..." mb={4} />
                    <SimpleGrid columns={3} spacing={4}>
                      {onlineCamera.map((camera) => (
                        <Checkbox
                          key={camera.p2purl}
                          value={camera.deviceId}
                          mb={2}
                          onChange={handleCheckboxChange}
                          isChecked={selectedDeviceIds.includes(
                            camera.deviceId
                          )}
                        >
                          {camera.name}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <SimpleGrid columns={3} spacing={4}>
                    {options.map((option) => (
                      <Checkbox
                        key={option.value}
                        value={option.value}
                        onChange={handleEventCheckboxChange}
                        isChecked={selectedEvents.includes(option.value)}
                      >
                        {option.label}
                      </Checkbox>
                    ))}
                  </SimpleGrid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Flex justify="center" width="100%">
              <Button colorScheme="blue" onClick={closeEdgeModal} mr={3}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleApply}>
                Apply
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isVideoModalOpen} onClose={closeVideoModal} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Video Player</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {videoUrl ? (
              <JessibucaPlayer
                style={{
                  height: "400px",
                  width: "600px",
                  position: "relative",
                  flexShrink: 0,
                  borderRadius: "8px",
                }}
                controls
                loadingText="Loading"
                src={videoUrl}
                decoder="/decoder.js"
                objectFit="fill"
              />
            ) : (
              <Text>No video URL available.</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* cloud filters */}

      <Modal isOpen={isCloudModalOpen} onClose={closeCloudModal} isCentered>
        <ModalOverlay />
        <ModalContent maxW="650px" maxH="80vh">
          <ModalHeader>Event Filter</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto">
            <Tabs variant="enclosed">
              <Center width="100%">
                <TabList>
                  <Tab>Camera List</Tab>
                  <Tab>Events</Tab>
                </TabList>
              </Center>
              <TabPanels>
                <TabPanel>
                  <Box width="100%">
                    <Input placeholder="Type to search..." mb={4} />
                    <SimpleGrid columns={3} spacing={4}>
                      {allCamera.map((camera) => (
                        <Checkbox
                          key={camera.p2purl}
                          value={camera.deviceId}
                          mb={2}
                          onChange={handleCheckboxChange}
                          isChecked={selectedDeviceIds.includes(
                            camera.deviceId
                          )}
                        >
                          {camera.name}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <SimpleGrid columns={3} spacing={4}>
                    {modelName.map((option) => (
                      <Checkbox
                        key={option.value}
                        value={option.value}
                        onChange={handleEventCheckboxChange}
                        isChecked={selectedEvents.includes(option.value)}
                      >
                        {option}
                      </Checkbox>
                    ))}
                  </SimpleGrid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Flex justify="center" width="100%">
              <Button colorScheme="blue" onClick={closeCloudModal} mr={3}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleApply}>
                Apply
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isImageModalOpen} onClose={closeImageModal} isCentered>
        <ModalOverlay />
        <ModalContent
          p={0}
          maxWidth="50%"
          maxHeight="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative" // Needed to position the close button
        >
          <ModalBody
            p={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative" // Needed to position the close button inside ModalBody
          >
            <ModalCloseButton
              position="absolute"
              top="4px" // Distance from the top
              right="5px" // Distance from the right
              zIndex={10} // Make sure the close button is above other elements
            />
            <Image
              src={selectedImage}
              alt="Selected"
              onLoad={(e) => {
                const image = e.target;
                // Apply the image dimensions directly to the parent element
                const modalContent = image.parentNode.parentNode;
                modalContent.style.width = `${image.naturalWidth}px`;
                modalContent.style.height = `${image.naturalHeight}px`;
              }}
              borderRadius="5px"
              objectFit="contain"
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Pagination Controls */}
      <Box mt={4} textAlign="center">
        <Flex justifyContent="center" alignItems="center">
          {/* Previous Button */}
          <Button onClick={handlePrevPage} mr={4} disabled={pageNumber === 1}>
            Previous
          </Button>

          {/* Conditionally show page number or full pagination */}
          {activeTab === "Edge Events" ? (
            <Text mx={2}>{pageNumber}</Text> // Only show the page number for "Edge Events"
          ) : (
            <>
              {/* Current Page and Total Pages */}
              <Text mx={2}>
                {pageNumber} / {cloudPages}
              </Text>
            </>
          )}

          {/* Next Button */}
          <Button onClick={handleNextPage} ml={4} disabled={pageNumber === cloudPages}>
            Next
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default Events;
